class ProposalCalculator {
    constructor() {
        this.calculate = {
            'Calendar': 150,
            'Social Integration': 250,
            'Photo Gallery': 0,
            'Number of Blog Posts': value => {
                let pricePerBlogPost = 25;
            
                return value * pricePerBlogPost;
            },
            'Number of Pages': value => {
                let cost = 1000;
                let pricePerPage = 800;
            
                if (value > 5) {
                    pricePerPage = 700;
                }
            
                return cost + (pricePerPage * value);
            },
            'Number of Forms': value => {
                let pricePerForm = 150;
            
                return value * pricePerForm;
            },
            'Number of Products': value => {
                let cost = 0;
            
                if (value > 0) {
                    cost = 1500;
                }
            
                return cost + (value * 50);
            }
        };

        this.canCalculate = this.canCalculate.bind(this);
        this.getCost      = this.getCost.bind(this);
    }

    canCalculate(lineItem) {
        return typeof this.calculate[lineItem] !== 'undefined';
    }

    getCost(lineItem, value) {

        // Make sure we can calculate the line item's cost
        if (this.canCalculate(lineItem)) {
            // Check if the cost depends on a function
            if (typeof this.calculate[lineItem] === 'function') {
                return this.calculate[lineItem](value);
            }

            // We don't want to return the fixed cost if the value is not true
            return value ? this.calculate[lineItem] : 0;
        }
        else {
            return null;
        }

    }
}

export default ProposalCalculator;