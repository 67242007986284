import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Button from '../../Button';
import Input from '../../Input';

import { addSitemapPageToTree, removeSitemapPageFromTree, updateSitemapPageOnTree } from '../../../redux/actions/sitemapTree';
import { clearSitemapPage, updateSitemapPage } from '../../../redux/actions/sitemapPage';

import styles from './styles';

const PageForm = props => {
    const {
        action, classes, name, rowInfo,
        addSitemapPageToTree, clearSitemapPage, removeSitemapPageFromTree, updateSitemapPage, updateSitemapPageOnTree, onRemove, onSubmit
    } = props;

    function handleRemove(e) {
        removeSitemapPageFromTree(rowInfo);

        if (typeof onRemove === 'function') {
            onRemove(e);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (action === 'add') {
            addSitemapPageToTree(rowInfo);
        }
        else if (action === 'edit') {
            updateSitemapPageOnTree(rowInfo);
        }

        clearSitemapPage();

        if (typeof onSubmit === 'function') {
            onSubmit(e);
        }
    }

    return (
        <form onSubmit={handleSubmit} className={classes.root}>
            <Input
                label='Name'
                onChange={e => updateSitemapPage('name', e.target.value)}
                value={name.value}
                errortext={name.valid ? '' : 'Required'}
            />

            <input type="submit" style={{ display: 'none' }} />

            <Grid container spacing={0} justify="flex-end">
                {
                    action === 'edit' ?
                        <Button
                            text="Remove"
                            size="large"
                            onClick={handleRemove}
                        />
                        :
                        ''
                }
                <Button
                    text={action === 'edit' ? 'Update Page': 'Create Page'}
                    size="large"
                    color="secondary"
                    variant="contained"
                    onClick={handleSubmit}
                />
            </Grid>
        </form>
    );
};

const mapStateToProps = ({ sitemapPage }) => ({ ...sitemapPage });

const actionCreators = {
    addSitemapPageToTree,
    clearSitemapPage,
    removeSitemapPageFromTree,
    updateSitemapPage,
    updateSitemapPageOnTree
};

export default withStyles(styles)(connect(mapStateToProps, actionCreators)(PageForm));
