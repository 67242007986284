import { Auth } from 'aws-amplify';

import { history } from '../config';

import { INIT } from '../reducers/user';
import { setSnackbar } from './snackbar';

export const SET_USER = 'SET_USER';

export const login = (email, password, rememberMe, redirect, setCognitoUser) => dispatch => (
  new Promise((resolve, reject) => {
    Auth.signIn(email.toLowerCase(), password)
      .then(cognitoUser => {

        // Turn on "session" so the user logs out when navigating away from the app
        if (!rememberMe) {
          window.addEventListener('beforeunload', e => {
            Auth.signOut();
            dispatch(setUser());
          });
        }

        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          console.log(setCognitoUser);
          setCognitoUser(cognitoUser);
          history.replace('/new-password');
          return;
        }
        
        resolve();
        
        if (redirect) {
          history.replace(redirect);
        } else {
          history.replace('/dashboard');
        }
      })
      .catch(err => {
        if (err.code === 'PasswordResetRequiredException') {
          Auth.forgotPassword(email.toLowerCase())
          .then(response => {
            resolve({
              response,
              redirect: `/reset-password?email=${email.toLowerCase()}`
            });
          })
          .catch(err => {
            dispatch(setSnackbar(err.message));
            reject(err);
          });
        } else {
          dispatch(setSnackbar(err.message));
          reject(err);
        }
      });
  })
);

export const setUser = user => {
  if (user) {
    return {
      type: SET_USER,
      payload: user
    }
  }
  // Empty user in store if no user is sent
  return {
    type: SET_USER,
    payload: INIT
  }
}
