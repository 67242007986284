import { combineReducers } from 'redux';

// Import reducers here
import calculator  from './calculator';
import modal       from './modal';
import proposal    from './proposal';
import sitemapPage from './sitemapPage';
import sitemapTree from './sitemapTree';
import snackbar    from './snackbar';
import user        from './user';

const rootReducer = combineReducers({
  calculator,
  proposal,
  modal,
  sitemapPage,
  sitemapTree,
  snackbar,
  user
});

export default rootReducer;
