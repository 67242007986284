import {
    CLEAR_SITEMAP_PAGE,
    UPDATE_SITEMAP_PAGE
} from '../actions/sitemapPage';

export const INIT = {
    name: {
        value: '',
        valid: true
    }
};
  
export default function(state = INIT, { type, payload }) {
    switch(type) {
        case CLEAR_SITEMAP_PAGE:
            return INIT;
        case UPDATE_SITEMAP_PAGE:
            return payload;
        default:
            return state;
    }
}