import cloneDeep   from 'lodash.clonedeep';

export const SET_CALCULATOR = 'SET_CALCULATOR';

export const updateLineItem = (key, cost, quantity) => (dispatch, getState) => {
    const { calculator } = cloneDeep(getState());

    if (quantity) {
        calculator.lineItems[key] = { cost, quantity };
    } else {
        // Remove line item if it doesn't contain anything
        delete calculator.lineItems[key];
    }

    return dispatch({
        type: SET_CALCULATOR,
        payload: calculator
    });
};