import React             from 'react';
import { Route, Switch } from 'react-router-dom';

import AdminDashboard from '../AdminDashboard';
import Navigation     from '../../components/Navigation';
import Wordpress      from '../Wordpress';

import PrivateRoute   from '../../components/PrivateRoute';

const UserTypeApp = props => {

  let userApp;

  const _AdminDashboard = props => <PrivateRoute component={AdminDashboard} {...props} />;
  const _Wordpress = props => <PrivateRoute component={Wordpress} {...props} />;

  let adminUserApp = (
    <Switch>
      <Route exact path='/dashboard' component={_AdminDashboard} />
      <Route path='/dashboard/proposals/create' component={_Wordpress} />
    </Switch>
  );

  // TODO: render different apps based on user types
  if (true) {
    userApp = adminUserApp;
  }

  return(
    <Navigation>
      { userApp }
    </Navigation>
  );
}

export default UserTypeApp;
