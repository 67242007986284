import React  from 'react';
import { Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip          from '@material-ui/core/Tooltip';

import { withStyles } from '@material-ui/core/styles';
import Button         from '@material-ui/core/Button';

import styles from './styles';

const StyledButton = props => {
  let { to, tooltip, text, loading, ...other } = props;
  
  if (to) {
    const MyLink = props => <Link to={to} {...props} />;

    other.component = MyLink;
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <Button {...other}>
          { loading ? <CircularProgress style={{ color: 'white' }} /> : text }
        </Button>
      </Tooltip>
    );
  } else {
    return (
      <Button {...other}>
        { loading ? <CircularProgress style={{ color: 'white' }} /> : text }
      </Button>
    );
  }
}

export default withStyles(styles)(StyledButton);
