import cloneDeep from 'lodash.clonedeep';
import { walk }  from 'react-sortable-tree';

import ProposalCalculator from '../../calculators/ProposalCalculator';
import validateField from '../../modules/validate-field';

import { updateLineItem } from './calculator';

export const SET_PROPOSAL = 'SET_PROPOSAL';

export const setProposalByField = (field, value) => (dispatch, getState) => {
    const { proposal } = cloneDeep(getState());

    const validated = validateField(field, value);

    proposal[field] = validated;

    const calculator = new ProposalCalculator();

    // Find cost if it exists
    const costExists = calculator.canCalculate(field);

    if (costExists) {
        const cost = calculator.getCost(field, validated.value);
        dispatch(updateLineItem(field, cost, validated.value));
    }

    return dispatch({
        type: SET_PROPOSAL,
        payload: proposal
    });
}

export const countSiteMapPages = () => (dispatch, getState) => {
    const { sitemapTree } = cloneDeep(getState());

    dispatch(setProposalByField('Number of Pages', 0));

    walk({
        treeData: sitemapTree,
        getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
            return number;
        },
        callback: rowInfo => {
            const { proposal } = cloneDeep(getState());

            proposal['Number of Pages'].value++;

            dispatch(setProposalByField('Number of Pages', proposal['Number of Pages'].value));
        },
        ignoreCollapsed: false
    });
};