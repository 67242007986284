const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    loginButton: {
        marginBottom: 16,
        marginTop: 16
    },
    bottomLinks: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-between'
        }
    },
    bottomLink: {
        textAlign: 'center'
    }
});

export default styles;