import React from 'react';
import {
  Route,
  Router,
  Switch
} from 'react-router-dom';

import { history } from './redux/config';

import UserTypeApp    from './containers/UserTypeApp';
import PrivateRoute   from './components/PrivateRoute';
import Auth           from './containers/Auth';
import ConnectedModal from './components/ConnectedModal';

const _Router = props => {

  const _UserTypeApp = props => <PrivateRoute component={UserTypeApp} {...props} />;

  return (
    <React.Fragment>
      
      {/* <Snackbar /> */}
      <ConnectedModal />

      <Router history={history}>
        <Switch>
          <Route path='/dashboard' component={_UserTypeApp} />
          <Route path='/' component={Auth} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default _Router;
