import cloneDeep     from 'lodash.clonedeep';
import validateField from '../../modules/validate-field';

export const CLEAR_SITEMAP_PAGE = 'CLEAR_SITEMAP_PAGE';
export const UPDATE_SITEMAP_PAGE = 'UPDATE_SITEMAP_PAGE';

export const clearSitemapPage = () => {
    return {
        type: CLEAR_SITEMAP_PAGE
    };
};

export const updateSitemapPage = (field, value) => (dispatch, getState) => {
    const { sitemapPage } = cloneDeep(getState());

    sitemapPage[field].value = value;
    sitemapPage[field].valid = validateField(field, value);

    dispatch({
        type: UPDATE_SITEMAP_PAGE,
        payload: sitemapPage
    });
};