import {
  ADD_SITEMAP_PAGE_TO_TREE,
  REMOVE_SITEMAP_PAGE_FROM_TREE,
  SET_SITEMAP_TREE,
  UPDATE_SITEMAP_PAGE_ON_TREE
} from '../actions/sitemapTree';

export const INIT = [{ title: 'Home', children: [] }];

export default function(state = INIT, { type, payload }) {

  switch(type) {

    case ADD_SITEMAP_PAGE_TO_TREE:
      return payload;
    case REMOVE_SITEMAP_PAGE_FROM_TREE:
      return payload;
    case SET_SITEMAP_TREE:
      return payload;
    case UPDATE_SITEMAP_PAGE_ON_TREE:
      return payload;
    default:
      return state;

  }

}
