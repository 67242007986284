import React, { useState } from 'react';
import { withStyles }      from '@material-ui/core/styles';
import { connect }         from 'react-redux';

import SortableTree from 'react-sortable-tree';

import Fab         from '@material-ui/core/Fab';
import Dialog      from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddIcon  from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import Button   from '../../components/Button';
import PageForm from './PageForm';

import { updateSitemapPage, clearSitemapPage } from '../../redux/actions/sitemapPage';
import { setSitemapTree } from '../../redux/actions/sitemapTree';

import styles from './styles';

const SitemapEditor = props => {
    const { classes, sitemapTree, setSitemapTree, clearSitemapPage, updateSitemapPage } = props;

    const [modal, setModal] = useState({
        open: false,
        title: '',
        component: null
    });

    function closeModal() {
        setModal({
            open: false,
            title: '',
            component: null
        });
    }

    function handleAddClick(e, rowInfo) {
        clearSitemapPage();

        setModal({
            title: 'Add Page',
            component: <PageForm onSubmit={closeModal} rowInfo={rowInfo} action="add" />
        });
    }

    function handleEditClick(e, rowInfo) {
        const { node } = rowInfo;

        updateSitemapPage('name', node.title);

        setModal({
            title: 'Edit Page',
            component: <PageForm onRemove={closeModal} onSubmit={closeModal} rowInfo={rowInfo} action="edit" />
        });
    }

    return (
        <React.Fragment>

            <div className={classes.root}>
                <SortableTree
                    treeData={sitemapTree}
                    onChange={setSitemapTree}
                    generateNodeProps={rowInfo => ({
                        buttons: [
                            <Button
                                onClick={e => handleEditClick(e, rowInfo)}
                                text={<EditIcon />}
                            />,
                            <Button
                                onClick={e => handleAddClick(e, rowInfo)}
                                text={<AddIcon />}
                            />,
                        ],
                    })}
                />
                <Fab onClick={handleAddClick} color="secondary" aria-label="Add" className={classes.fab}>
                    <AddIcon />
                </Fab>
            </div>

            <Dialog open={modal.open !== false} onClose={closeModal} aria-labelledby={modal.title}>
                <DialogTitle>{modal.title}</DialogTitle>
                <div className={classes.dialogContent}>
                    {modal.component}
                </div>
            </Dialog>
            
        </React.Fragment>
    );
};

const mapStateToProps = ({ sitemapTree }) => ({ sitemapTree });

const actionCreators = { clearSitemapPage, setSitemapTree, updateSitemapPage };

export default withStyles(styles)(connect(mapStateToProps, actionCreators)(SitemapEditor));
