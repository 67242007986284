import React, { Component }   from 'react';
import queryString            from 'query-string';
import { withStyles }         from '@material-ui/core/styles';
import { connect }            from 'react-redux';

import validateForm  from '../../../modules/validate-form';
import validateField from '../../../modules/validate-field';

import { login } from '../../../redux/actions/user';

import Checkbox         from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid             from '@material-ui/core/Grid';

import Logo       from '../../../components/Logo';
import AuthForm   from '../../../components/AuthForm/AuthForm';
import Button     from '../../../components/Button';
import Input      from '../../../components/Input';

import styles from './styles';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email:             { value: '', valid: true },
      loginPassword:     { value: '', valid: true },
      rememberMeChecked: true,
      loading:           false
    };

    this.handleError = this.handleError.bind(this);
  }

  handleChange(field, e) {
    const { value } = e.target;
    const valid = validateField(field, value, this.state);

    this.setState({
      [field]: { value, valid }
    });
  }

  handleCheckboxChange(field, e) {
    this.setState({
      [field]: e.target.checked
    });
  }

  handleError(error) {
    console.error(error);
    if (typeof this.props.onError === 'function') {
      this.props.onError(error);
    }
  }

  handleLogin(e) {
    e.preventDefault();
    const { login, location, setCognitoUser } = this.props;
    const { email, loginPassword, rememberMeChecked } = this.state;

    const formObject = { email, loginPassword };
    const { formValid, emptyFields } = validateForm(formObject);

    const { redirect } = queryString.parse(location.search);

    if (formValid) {
      this.setState({ loading: true }, () => {
        
        login(email.value, loginPassword.value, rememberMeChecked, redirect, setCognitoUser)
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(err => {
          this.setState({ loading: false });
        });
        
      });
    } else {
      emptyFields.forEach(fieldName => {
        this.setState({[fieldName]: {value: '', valid: false}});
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    return(
      <AuthForm onSubmit={this.handleLogin.bind(this)}>

        <Logo />

        <Input
          id='email'
          label='Email Address'
          onChange={this.handleChange.bind(this, 'email')}
          value={this.state.email.value}
          errortext={this.state.email.valid ? '' : 'Please enter a valid Email Address'} />
        <Input
          id='loginPassword'
          label='Password'
          type='password'
          onChange={this.handleChange.bind(this, 'loginPassword')}
          value={this.state.loginPassword.value}
          errortext={this.state.loginPassword.valid ? '' : 'Please enter a Password'} />
        <Grid container spacing={0} alignItems="center" justify="flex-start">
          <span className="label">Remember me?</span>
          <Checkbox
            checked={this.state.rememberMeChecked}
            onChange={this.handleCheckboxChange.bind(this, 'rememberMeChecked')}
            value="remember"
            className="remember-me-checkbox"
          />
        </Grid>

        <input ref="formSubmit" type="submit" style={{ display: 'none' }} />

        <Button
          className={classes.loginButton}
          onClick={this.handleLogin.bind(this)}
          text={loading ? <CircularProgress style={{ color: 'white' }} /> : 'Login'}
          color="primary"
          size="large"
          disabled={loading}
          variant="contained"
          fullWidth={true} />

        <div className={classes.bottomLinks}>
          <Button className={classes.bottomLink} text="Forgot Password" to="/forgot-password" />
          <Button className={classes.bottomLink} text="Register New Account" to="/register" />
        </div>

      </AuthForm>
    );
  }
}

export default connect(null, { login })(withStyles(styles)(Login));