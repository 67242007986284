import { Auth } from 'aws-amplify';

// AWS Project Constants
const awsmobile = {
  Auth: {
    identityPoolId: 'us-west-2:bced1aa7-5754-4699-a680-28dd570c2d04',
    region: 'us-west-2',
    userPoolId: 'us-west-2_4ONCRyYTJ',
    userPoolWebClientId: 'sas7mfhsnh2phh8fqu1rd652n'
  },
//   API: {
//     endpoints: [
//       {
//         name: 'ClutchAPI',
//         endpoint: 'https://8v9xyktvhf.execute-api.us-east-1.amazonaws.com/Prod',
//         region: 'us-east-1',
//         custom_header: async () => {
//           // return { Authorization : 'token' } 
//           // Alternatively, with Cognito User Pools use this:
//           return {
//             Authorization: (await Auth.currentSession()).idToken.jwtToken
//           }
//         }
//       }
//     ]
//   }
};

export default awsmobile;