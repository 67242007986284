import { connect } from 'react-redux';

import Modal from '../Modal';

import { closeModal } from '../../redux/actions/modal';

const mapStateToProps = ({ modal }) => ({ ...modal });

const actionCreators = { closeModal };

export default connect(mapStateToProps, actionCreators)(Modal);
