const styles = theme => ({
    root: {
        flex: 1,
        position: 'relative'
    },
    dialogContent: {
        minWidth: 400
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    }
});

export default styles;