import React, { Component } from 'react';
import { Auth }             from 'aws-amplify';
import { withStyles }       from '@material-ui/core/styles';

import validateForm      from '../../../modules/validate-form';
import validateField     from '../../../modules/validate-field';

import { CircularProgress } from '@material-ui/core';

import AuthForm   from '../../../components/AuthForm';
import Logo       from '../../../components/Logo';
import Button     from '../../../components/Button';
import Input      from '../../../components/Input';

import styles from './styles';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: { value: '', valid: true },
      loading: false
    };

    this.handleError = this.handleError.bind(this);
  }

  handleChange(field, e) {
    const { value } = e.target;
    const valid = validateField(field, value, this.state);

    this.setState({
      [field]: { value, valid }
    });
  }

  handleForgotPassword(e) {
    e.preventDefault();
    const { email } = this.state;

    const formObject = { email };
    const { formValid, emptyFields } = validateForm(formObject);

    if (formValid) {
      this.setState({ loading: true }, () => {
        Auth.forgotPassword(email.value)
          .then(response => {
            this.setState({ loading: false });
            this.props.history.push(`/reset-password?email=${email.value}`);
          })
          .catch(err => {
            this.setState({ loading: false });
            this.handleError(err.message);
          });
      });
    } else {
      emptyFields.forEach(fieldName => {
        this.setState({[fieldName]: {value: '', valid: false}});
      });
    }
  }

  handleError(error) {
    if (typeof this.props.onError === 'function') {
      this.props.onError(error);
    }
  }

  render() {
    let { loading } = this.state;

    return(
      <AuthForm onSubmit={this.handleForgotPassword.bind(this)}>

        <Logo />
          
          <Input
            id='email'
            label='Email Address'
            onChange={this.handleChange.bind(this, 'email')}
            value={this.state.email.value}
            errortext={this.state.email.valid ? '' : 'Please enter a valid Email Address'} />

          <input ref="formSubmit" type="submit" style={{ display: 'none' }} />

          <Button
            color="primary"
            disabled={loading}
            fullWidth={true}
            onClick={this.handleForgotPassword.bind(this)}
            text={loading ? <CircularProgress style={{ color: 'white' }}/> : 'Request Password Reset'}
            size="large"
            variant="contained"
          />

      </AuthForm>
    );
  }
}

export default withStyles(styles)(ForgotPassword);