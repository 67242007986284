import React          from 'react';
import IconButton     from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField      from '@material-ui/core/TextField';
import { format }     from 'react-phone-input-auto-format';

const Input = (props) => {

  let { value } = props;
  const { onChange, errortext, icon, iconClick, type, ...other } = props;

  const formHelpProps = {
    error: errortext.length > 0
  };

  function handleChange(e) {
    let event = e;
    if(type === 'tel') {
      // if number is too long, chop off last number
      if(event.target.value.length > 14) {
        event.target.value = event.target.value.slice(0, -1);
      } // otherwise format
      event.target.value = format(event.target.value);
    }
    if(typeof onChange === 'function') {
      onChange(event);
    }
  }

  return (
    // set defaults for text fields app-wide
    <TextField
      fullWidth={true}
      margin='normal'
      {...other}
      type={type || 'text'}
      value={value}
      onChange={handleChange}
      helperText={errortext}
      error={errortext.length > 0}
      FormHelperTextProps={formHelpProps}
      className='text-field'
      InputProps={{
        endAdornment:
          icon ?
            <InputAdornment position='end'>
              <IconButton
                className='input-icon-style'
                aria-label='icon-button'
                disabled={!(typeof iconClick === 'function')}
                onClick={iconClick}>

                { icon }

              </IconButton>
            </InputAdornment>
            :
            null
      }} />
  );
}

export default Input;