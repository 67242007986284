import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const AdminDashboard = props => {
  return(
    <div>
    </div>
  );
}

export default withStyles(styles)(AdminDashboard);
