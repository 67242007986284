import React, { useState, useEffect } from 'react';
import { withRouter }                 from 'react-router-dom';
import { withStyles }                 from '@material-ui/core/styles';

import Grid       from '@material-ui/core/Grid';
import Step       from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper    from '@material-ui/core/Stepper';

import Button from '../Button';

import styles from './styles';

const CustomStepper = props => {
    const { classes, children, location } = props;

    const [step, setStep] = useState(0);
    const [completed, setCompleted] = useState({});

    useEffect(handleMount, []);
    useEffect(handleLocationUpdate, [location]);

    function handleMount() {
        handleLocationUpdate();
    }

    function handleLocationUpdate() {
        const { pathname } = location;
        const _step = getStepFromPathname(pathname);

        setStep(_step);
    }

    // function getPathnameFromStep(stepNumber) {
    //     let pathname = children[0].props.pathname;

    //     children.forEach((step, index) => {
    //         if (index === stepNumber) {
    //             pathname = step.props.pathname;
    //         }
    //     });

    //     return pathname;
    // }

    function getStepFromPathname(pathname) {
        let _step = 0;

        children.forEach((step, index) => {
            if (step.props.pathname === pathname) {
                _step = index;
            }
        })

        return _step;
    }

    function totalSteps() {
        return children.length;
    }

    function handleComplete() {
        const _completed = Object.assign({}, completed);

        _completed[step] = true;
        setCompleted(_completed);
        handleNext();
    }

    function handleNext() {
        let activeStep;

        if (isLastStep() && !allStepsCompleted()) {
            activeStep = children.findIndex((step, i) => !(i in completed));
        } else {
            activeStep = step + 1;
        }

        setStep(activeStep);

        // const pathname = getPathnameFromStep(activeStep);

        // history.push(pathname);
    }

    function handleBack() {
        setStep(step - 1);
    }

    function completedSteps() {
        return Object.keys(completed).length;
    }

    function isLastStep() {
        return step === totalSteps() - 1;
    }

    function allStepsCompleted() {
        return (completedSteps() === totalSteps());
    }

    return (
        <div className={classes.root}>
            <Stepper nonLinear activeStep={step} className={classes.stepper}>
                {
                    children.map((step, index) => {
                        const { label } = step.props;

                        return (
                            <Step key={`step-${index}`}>
                                <StepButton onClick={() => setStep(index)} completed={completed[index]}>
                                    {label}
                                </StepButton>
                            </Step>
                        )
                    })
                }
            </Stepper>
            <React.Fragment>
                {
                    allStepsCompleted() ?
                        <div>All Steps Completed</div>
                        :
                        <div className={classes.stepContainer}>
                            <div className={classes.stepComponent}>
                                { children[step] }
                                {/* {
                                    children.map((child, index) => (
                                        <Route
                                            key={`stepper-child-${index}`}
                                            path={child.props.pathname}
                                            render={props => {
                                                delete props.staticContext;

                                                return (
                                                    <div className={classes.route} {...props}>
                                                        {child.props.children}
                                                    </div>
                                                );
                                            }}
                                        />
                                    ))
                                } */}
                            </div>
                            <Grid className={classes.stepButtons} container spacing={16}>
                                <Grid item>
                                    <Button
                                        disabled={step === 0}
                                        onClick={handleBack}
                                        size="large"
                                        text="Back"
                                        variant="contained"
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        disabled={step === totalSteps() - 1}
                                        onClick={handleNext}
                                        size="large"
                                        text="Next"
                                        variant="contained"
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={completed[step]}
                                        variant="contained"
                                        size="large"
                                        text={completed[step] ? 'Completed' : 'Complete Step'}
                                        onClick={handleComplete}
                                        color="primary"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                }
            </React.Fragment>
        </div>
    );
};

export default withStyles(styles)(withRouter(CustomStepper));
