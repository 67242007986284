import React            from 'react';
import Amplify          from 'aws-amplify';
import ReactDOM         from 'react-dom';
import { Provider }     from 'react-redux';
import { PersistGate }  from 'redux-persist/integration/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';

import Router               from './Router';
import { store, persistor } from './redux/config';
import * as serviceWorker   from './serviceWorker';
import aws_config_local     from './aws-config.local';
import aws_config_dev       from './aws-config.dev';
import aws_config_staging   from './aws-config.staging';
import aws_config_master from './aws-config.master';

import theme from './theme';

import 'react-sortable-tree/style.css';
import './index.css';

let AWS_CONFIG;
// load the correct aws config options
switch(process.env.REACT_APP_ENV) {
  case 'prod':
    AWS_CONFIG = aws_config_master;
  break
  case 'staging':
    AWS_CONFIG = aws_config_staging;
  break
  case 'dev':
    AWS_CONFIG = aws_config_dev
  break
  default:
    AWS_CONFIG = aws_config_local
}

Amplify.configure(AWS_CONFIG);

const App = (props) => {
  return (
    <Provider store={store}>
      <PersistGate
        loading={ <CircularProgress size={150} /> }
        persistor={persistor}>

        <ThemedApp />

      </PersistGate>
    </Provider>
  );
}

let ThemedApp = props => {
  const muiTheme = createMuiTheme(theme);

  return(
    <MuiThemeProvider theme={muiTheme}>
      <Router />
    </MuiThemeProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
