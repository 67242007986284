import validator from 'validator';

const validateField = (field, value, state) => {
  let valid;
  let message;

  switch (field) {
    case 'Email':
      valid = validator.isEmail(value);
      break;
    case 'Phone Number':
      valid = validator.isMobilePhone(value, 'en-US');
      break;
    case 'password':
      valid = validator.matches(value, /(?=^.{12,255}$)((?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=(.*\d){1,}))((?!.*[",;&|'])|(?=(.*\W){1,}))(?!.*[",;&|'])^.*$/);
      break;
    case 'passwordMatch':
      valid = (value === state.password.value);
      break;
    case 'code':
      valid = value.length > 1;
      break;
    case 'Number of Forms':
      value = value < 0 ? 0 : parseInt(value, 10);
      valid = validator.isInt(`${value}`) && value >= 0; // isInt() expects a string for some reason
      break;
    case 'Number of Products':
      value = value < 0 ? 0 : parseInt(value, 10);
      valid = validator.isInt(`${value}`) && value >= 0; // isInt() expects a string for some reason
      break;
    case 'Number of Blog Posts':
      value = value < 0 ? 0 : parseInt(value, 10);
      valid = validator.isInt(`${value}`) && value >= 0; // isInt() expects a string for some reason
      break;
    default:
      valid = true;
      break;
  }

  return { value, valid, message };
};

export default validateField;