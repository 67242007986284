import React, { useEffect } from 'react';

import FullscreenLoader  from '../FullscreenLoader';
import useAuthentication from '../../modules/use-authentication';

const PublicRoute = (props) => {
  // get the user from our "Hook" function, which maintains a local React state
  const user = useAuthentication();

  // this logic needs to be here otherwise it will be run at the same time as 'render' instead of 'componentWillMount'
  useEffect(() => {
    // don't do anything while we wait for the user to get set
    if(user === null) return;
    // if we have a user, we need to send them to the app
    if(user) props.history.replace('/dashboard');
  }, [user]);

  // if user is null, we are still waiting for a response
  if(user === null) return <FullscreenLoader />;

  const Comp = props.component;

  return (
    <Comp {...props} />
  );
}

export default PublicRoute;