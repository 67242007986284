import React, { useState } from 'react';
import { connect }         from 'react-redux';
import { withRouter }      from 'react-router';
import { Auth }            from 'aws-amplify';

// Material UI Core
import { withStyles } from '@material-ui/core/styles';
import AppBar         from '@material-ui/core/AppBar';
import Drawer         from '@material-ui/core/Drawer';
import Toolbar        from '@material-ui/core/Toolbar';
import List           from '@material-ui/core/List';
import ListItem       from '@material-ui/core/ListItem';
import ListItemIcon   from '@material-ui/core/ListItemIcon';
import ListItemText   from '@material-ui/core/ListItemText';
import Typography     from '@material-ui/core/Typography';
import IconButton     from '@material-ui/core/IconButton';
import Hidden         from '@material-ui/core/Hidden';

// Material Icons
import CreateIcon        from '@material-ui/icons/Create';
import ExitToAppIcon     from '@material-ui/icons/ExitToApp';
import HomeIcon          from '@material-ui/icons/Home';
import MenuIcon          from '@material-ui/icons/Menu';
import ViewListIcon      from '@material-ui/icons/ViewList';

import Logo from '../../components/Logo';

import titles from './page-titles';

// Redux
import { setUser } from '../../redux/actions/user';

import styles from './styles';

const Navigation = (props) => {

  const { classes, location, theme, history } = props;

  const [mobileOpen, setMobileOpen] = useState(false);

  function handleClick(to) {
    setMobileOpen(false);
    history.push(to);
  }

  function handleLogout() {
    props.setUser();
    Auth.signOut();
    props.history.replace('/');
  }

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Logo />
      </div>
      <List>
        <ListItem button onClick={e => handleClick('/dashboard')}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={e => handleClick('/dashboard/proposals/create')}>
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText primary="New Proposal" />
        </ListItem>
        <ListItem button onClick={e => handleClick('/dashboard/proposals')}>
          <ListItemIcon>
            <ViewListIcon />
          </ListItemIcon>
          <ListItemText primary="My Proposals" />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemText primary="Logout" />
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={e => setMobileOpen( !mobileOpen )}
            className={classes.navIconHide}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            {titles[location.pathname]}
          </Typography>
        </Toolbar>
      </AppBar>

      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={e => setMobileOpen( !mobileOpen )}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            docked: classes.drawerDocked,
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        { props.children }
      </main>
      
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: userObj => {
      dispatch(setUser(userObj));
    }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Navigation)));
