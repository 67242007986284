const drawerWidth = 283;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        position: 'absolute',
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    navIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: {
        ...theme.mixins.toolbar,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    logo: {
        height: 48
    },
    drawerDocked: {
        height: '100%'
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
    },
    wordpressIcon: {
        height: '1em',
        marginLeft: 5,
        marginRight: 3,
        width: '1em',
        opacity: 0.54
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column'
    },
});

export default styles;