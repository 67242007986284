import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Step = props => {
    const { classes, children} = props;

    return (
        <div className={classes.root} >
            {children}
        </div>
    );
};

export default withStyles(styles)(Step);
