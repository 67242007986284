import { SET_PROPOSAL } from '../actions/proposal';

export const INIT = {
    'Number of Pages': {
        value: 0,
        valid: true
    },
    'Number of Forms': {
        value: 0,
        valid: true
    },
    'Number of Blog Posts': {
        value: 0,
        valid: true
    },
    'Calendar': {
        value: false,
        valid: true
    },
    'Social Integration': {
        value: false,
        valid: true
    },
    'Photo Gallery': {
        value: false,
        valid: true
    },
    'Number of Products': {
        value: 0,
        valid: true
    },
    'First Name': {
        value: '',
        valid: true
    },
    'Last Name': {
        value: '',
        valid: true
    },
    'Email': {
        value: '',
        valid: true
    },
    'Phone Number': {
        value: '',
        valid: true
    }
};
  
export default function(state = INIT, { type, payload }) {
    switch(type) {
        case SET_PROPOSAL:
            return payload;
        default:
            return state;
    }
}