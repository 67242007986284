import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button            from '@material-ui/core/Button';
import Dialog            from '@material-ui/core/Dialog';
import DialogTitle       from '@material-ui/core/DialogTitle';
import DialogContent     from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions     from '@material-ui/core/DialogActions';

import styles from './styles';

const Modal = props => {
    const { classes, closeModal, open, title, message, buttons, onClose } = props;

    function handleClose(e) {
        if (typeof onClose === 'function') {
            onClose(e);
        }

        closeModal();
    }

    function handleButtonClick(e, cb) {
        if (typeof cb === 'function') {
            cb(e);
        }

        closeModal();
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {
                        React.isValidElement(message) ?
                        message 
                        :
                        <DialogContentText id="alert-dialog-description">
                            {message}
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                {
                    buttons.length > 0 ?
                        buttons.map(({ onClick, color, label }, index) => (
                            <Button key={`modal-button-${index}`} onClick={e => handleButtonClick(e, onClick)} color={color || "primary"}>
                                {label}
                            </Button>
                        ))
                        :
                        <Button onClick={handleClose} color="primary">
                            OK
                        </Button>
                }
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withStyles(styles)(Modal);
