const styles = theme => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    },
    paper: {
        alignItems: 'center',
        background: '#fff',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        paddingLeft: 32,
        paddingRight: 32,
        transition: '1s all',
        width: '100%',
        zIndex: 999,
        [theme.breakpoints.up('sm')]: {
            borderRadius: 13,
            height: 'unset',
            paddingLeft: 48,
            paddingRight: 48,
            paddingTop: 64,
            paddingBottom: 64,
            maxWidth: 400
        }
    }
});

export default styles;