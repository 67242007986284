import React, { Component }   from 'react';
import { Auth }               from 'aws-amplify';
import { connect }            from 'react-redux';
import { withStyles }         from '@material-ui/core/styles';

import validateForm      from '../../../modules/validate-form';
import validateField     from '../../../modules/validate-field';

import { CircularProgress } from '@material-ui/core';

import AuthForm   from '../../../components/AuthForm';
import Logo       from '../../../components/Logo';
import Button     from '../../../components/Button';
import Input      from '../../../components/Input';

import styles from './styles';

class NewPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: { value: '', valid: true },
      passwordMatch: { value: '', valid: true },
      loading: false
    };

    this.handleError = this.handleError.bind(this);
  }

  handleChange(field, e) {
    const { value } = e.target;
    const valid = validateField(field, value, this.state);

    this.setState({
      [field]: { value, valid }
    });
  }

  handleNewPassword(e) {
    e.preventDefault();
    const { cognitoUser } = this.props;
    const { password } = this.state;

    const formObject = { password };
    const { formValid, emptyFields } = validateForm(formObject);

    if (formValid) {
      this.setState({ loading: true }, () => {
        Auth.cleanCachedItems()
        .then(res => {
            Auth.completeNewPassword(cognitoUser, password.value)
            .then(user => {
                // 'user' object returned from promise is useless
                // because it doesn't indicate whether or not the
                // password was reset/user's status was changed
                // So... guess we will just assume that happened
                // and hope any errors get caught in the catch below
                this.setState({ loading: false });
                this.props.history.push('/dashboard');
            })
            .catch(err => {
                this.setState({ loading: false });
                console.error(err);
                this.handleError(err.message);
            });
        })
        .catch(err => {
            this.setState({ loading: false });
            console.error(err);
            this.handleError(err.message);
        });
      });
    } else {
      emptyFields.forEach(fieldName => {
        this.setState({[fieldName]: { value: '', valid: false }});
      });
    }
  }

  handleError(error) {
    if (typeof this.props.onError === 'function') {
      this.props.onError(error);
    }
  }

  render() {
    let { loading } = this.state;

    return(
      <AuthForm onSubmit={this.handleNewPassword.bind(this)}>

        <Logo />

        <Input
          id='password'
          label='Password'
          type='password'
          onChange={this.handleChange.bind(this, 'password')}
          value={this.state.password.value}
          errortext={this.state.password.valid ? '' : 'Please enter a valid password'} />
        <Input
          id='password-match'
          label='Confirm Password'
          type='password'
          onChange={this.handleChange.bind(this, 'passwordMatch')}
          value={this.state.passwordMatch.value}
          errortext={this.state.passwordMatch.valid ? '' : 'Passwords must match'} />

        <input ref="formSubmit" type="submit" style={{ display: 'none' }} />

        <Button
          color="primary"
          disabled={loading}
          fullWidth={true}
          onClick={this.handleNewPassword.bind(this)}
          size="large"
          text={loading ? <CircularProgress style={{ color: 'white' }} /> : 'Set Password'}
          variant="contained"
        />

      </AuthForm>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(withStyles(styles)(NewPassword));