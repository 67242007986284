const styles = theme => ({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    stepper: {
        backgroundColor: 'transparent'
    },
    stepContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    stepComponent: {
        display: 'flex',
        flexGrow: 1
    },
    stepButtons: {
        flexShrink: 1
    },
    route: {
        display: 'flex',
        flex: 1
    }
});

export default styles;