const styles = theme => ({
    root: {
        display: 'flex',
        flex: 1
    },
    stepper: {
        // flex: 1
    }
});

export default styles;