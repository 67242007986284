import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import styles from './styles';

const CustomCheckbox = props => {
    const { checked, label, onChange, value, checkboxProps, other } = props;

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    value={value}
                    {...checkboxProps}
                />
            }
            label={label}
            {...other}
        />
    );
};

export default withStyles(styles)(CustomCheckbox);
