import React, { useEffect } from 'react';
import { connect }          from 'react-redux';
import { withStyles }       from '@material-ui/core/styles';

import Grid      from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import Paper     from '@material-ui/core/Paper';

import Calculator from '../../../components/Calculator';
import Checkbox   from '../../../components/Checkbox';
import Input      from '../../../components/Input';

import { countSiteMapPages, setProposalByField } from '../../../redux/actions/proposal';

import styles from './styles';


const Features = props => {
    const { classes, countSiteMapPages, proposal, setProposalByField } = props;

    useEffect(() => {
        countSiteMapPages();
    }, []);

    return (
        <div className={classes.root}>
            <h2>Additional Features</h2>
            <p>Are there any other features needed?</p>
            <form onSubmit={e => {}}>
                <Grid container spacing={16} direction="row">
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={16}>
                            <Grid item xs={7}>
                                <Input
                                    type="number"
                                    label='Number of Blog Posts'
                                    onChange={e => setProposalByField('Number of Blog Posts', e.target.value)}
                                    value={proposal['Number of Blog Posts'].value}
                                    errortext={proposal['Number of Blog Posts'].valid ? '' : 'Please enter a whole number'}
                                />
                            </Grid>
                            <Grid item xs={7}>    
                                <Input
                                    type="number"
                                    label='Number of Forms'
                                    onChange={e => setProposalByField('Number of Forms', e.target.value)}
                                    value={proposal['Number of Forms'].value}
                                    errortext={proposal['Number of Forms'].valid ? '' : 'Please enter a whole number'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <Checkbox
                                        label="Calendar"
                                        onChange={e => setProposalByField('Calendar', e.target.checked)}
                                        value="calendar"
                                        checked={proposal['Calendar'].value}
                                    />
                                    <Checkbox
                                        label="Social Integration"
                                        onChange={e => setProposalByField('Social Integration', e.target.checked)}
                                        value="socialIntegration"
                                        checked={proposal['Social Integration'].value}
                                    />
                                    <Checkbox
                                        label="Photo Gallery"
                                        onChange={e => setProposalByField('Photo Gallery', e.target.checked)}
                                        value="photoGallery"
                                        checked={proposal['Photo Gallery'].value}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={7}>
                                <Input
                                    type="number"
                                    label="Number of Products"
                                    onChange={e => setProposalByField('Number of Products', e.target.value)}
                                    value={proposal['Number of Products'].value}
                                    errortext={proposal['Number of Products'].valid ? '' : 'Please enter a whole number'}
                                />
                            </Grid>  
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper>
                            <Calculator />
                        </Paper>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

const mapStateToProps = ({ proposal }) => ({ proposal });

export default withStyles(styles)(connect(mapStateToProps, { countSiteMapPages, setProposalByField })(Features));
