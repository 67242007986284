import { SET_CALCULATOR } from '../actions/calculator';

export const INIT = {
    lineItems: {}
};

export default function(state = INIT, { type, payload }) {
    switch(type) {
        case SET_CALCULATOR:
            return payload;
        default:
            return state;
    }
}