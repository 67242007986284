import {
    addNodeUnderParent,
    changeNodeAtPath,
    getNodeAtPath,
    insertNode,
    removeNodeAtPath
} from 'react-sortable-tree';

export const ADD_SITEMAP_PAGE_TO_TREE      = 'ADD_SITEMAP_PAGE_TO_TREE';
export const REMOVE_SITEMAP_PAGE_FROM_TREE = 'REMOVE_SITEMAP_PAGE_FROM_TREE';
export const SET_SITEMAP_TREE              = 'SET_SITEMAP_TREE';
export const UPDATE_SITEMAP_PAGE_ON_TREE   = 'UPDATE_SITEMAP_PAGE_ON_TREE';

export const addSitemapPageToTree = rowInfo => (dispatch, getState) => {
    const { sitemapPage, sitemapTree } = getState();
    
    let payload;

    const newNode = {
        title: sitemapPage.name.value,
        children: [],
        expanded: false
    };

    if (rowInfo) {

        const { path } = rowInfo;
    
        const parentNode = getNodeAtPath({
            treeData: sitemapTree,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            ignoreCollapsed: true
        });
    
        const getNodeKey = ({ node: object, treeIndex: number }) => {
            return number;
        };
    
        const parentKey = getNodeKey(parentNode);
    
        const { treeData } = addNodeUnderParent({
            treeData: sitemapTree,
            path,
            expandParent: true,
            newNode,
            parentKey,
            getNodeKey: ({ treeIndex }) => treeIndex,
        });

        payload = treeData;

    } else {

        const { treeData } = insertNode({
            treeData: sitemapTree,
            depth: 0,
            minimumTreeIndex: sitemapTree.length + 1,
            newNode,
            getNodeKey: ({ treeIndex }) => treeIndex
        });

        payload = treeData;

    }

    dispatch({
        type: ADD_SITEMAP_PAGE_TO_TREE,
        payload
    });
};

export const removeSitemapPageFromTree = rowInfo => (dispatch, getState) => {
    const { sitemapTree } = getState();
    const { path } = rowInfo;

    const payload = removeNodeAtPath({
        treeData: sitemapTree,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        ignoreCollapsed: false
    });

    dispatch({
        type: REMOVE_SITEMAP_PAGE_FROM_TREE,
        payload
    });
};

export const setSitemapTree = tree => {
    return {
        type: SET_SITEMAP_TREE,
        payload: tree
    };
};

export const updateSitemapPageOnTree = rowInfo => (dispatch, getState) => {
    const { sitemapPage, sitemapTree } = getState();
    const { node, path } = rowInfo;

    node.title = sitemapPage.name.value;

    const payload = changeNodeAtPath({
        treeData: sitemapTree,
        path,
        getNodeKey: ({ node, treeIndex }) => treeIndex,
        newNode: node
    });

    dispatch({
        type: UPDATE_SITEMAP_PAGE_ON_TREE,
        payload
    });
};