import React, { useState, useEffect }         from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { withStyles }              from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import PrivateRoute from '../../components/PrivateRoute';
import PublicRoute  from '../../components/PublicRoute';

import Login          from './Login';
import Register       from './Register';
import ForgotPassword from './ForgotPassword';
import NewPassword    from './NewPassword';
import ResetPassword  from './ResetPassword';

import styles from './styles';

const Auth = props => {
    const { classes } = props;

    // DO NOT REFACTOR WITHOUT REALLY THINKING HARD
    // The /new-password component requires the use of a method on
    // the cognitoUser object, and I know you want to use redux
    // but that would be a bad idea... You've already tried that... twice.
    const [cognitoUser, setCognitoUser] = useState(null);

    useEffect(() => {
        console.log(cognitoUser);
    }, [cognitoUser]);

    const _Login = (props) => <Login  setCognitoUser={setCognitoUser} {...props} />;
    const _LoginPublic = (props) => <PublicRoute component={_Login} {...props} />;

    const _Register = (props) => <Register {...props} />;
    const _RegisterPublic = (props) => <PublicRoute component={_Register} {...props} />;

    const _NewPassword = (props) => <NewPassword {...props} />;
    const _NewPasswordPrivate = (props) => <PrivateRoute component={_NewPassword} cognitoUser={cognitoUser} {...props} />;

    const _ForgotPassword = (props) => <ForgotPassword {...props} />;
    const _ForgotPasswordPublic = (props) => <PublicRoute component={_ForgotPassword} {...props} />;

    const _ResetPassword = (props) => <ResetPassword {...props} />;
    const _ResetPasswordPublic = (props) => <PublicRoute component={_ResetPassword} {...props} />;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={5}>
                <Switch>
                    <Route exact path="/register" component={_RegisterPublic} />
                    <Route exact path="/new-password" component={_NewPasswordPrivate} />
                    <Route exact path="/forgot-password" component={_ForgotPasswordPublic} />
                    <Route exact path="/reset-password" component={_ResetPasswordPublic} />
                    <Route exact path="/" component={_LoginPublic} />
                    <Route path='/*' render={() => <Redirect to='/' />} />
                </Switch>
            </Paper>
        </div>
    );
}

export default withStyles(styles)(Auth);