import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import List                    from '@material-ui/core/List';
import ListItem                from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText            from '@material-ui/core/ListItemText';

import styles from './styles';

const Calculator = props => {
    const { classes, calculator } = props;
    const { lineItems } = calculator;

    let totalCost = 0;

    return (
        <List className={classes.root}>
            {
                Object.keys(lineItems).map(key => {
                    const { cost, quantity } = lineItems[key];
                    let secondary = quantity;

                    if (typeof quantity === 'boolean') {
                        secondary = '';
                    }

                    totalCost += cost;

                    return (
                        <ListItem key={key}>
                            <ListItemText primary={key} secondary={secondary} />
                            <ListItemSecondaryAction>${cost}</ListItemSecondaryAction>
                        </ListItem>
                    );
                })
            }
            <ListItem classes={{ root: classes.totalCost }}>
                <ListItemText primary="Total Cost" />
                <ListItemSecondaryAction>${totalCost}</ListItemSecondaryAction>
            </ListItem>
        </List>
    );
};

const mapStateToProps = ({ calculator, proposal }) => ({ calculator, proposal });

export default withStyles(styles)(connect(mapStateToProps)(Calculator));
