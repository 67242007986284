import React          from 'react';
import { withStyles } from '@material-ui/core/styles';

import SitemapEditor from '../../components/SitemapEditor';
import Step          from '../../components/Stepper/Step';
import Stepper       from '../../components/Stepper';

import Complete from './Complete';
import Features from './Features';

import styles from './styles';

const Wordpress = props => {
    const { classes } = props;

    return (
        <div className={classes.root}>
            <Stepper classes={{ root: classes.stepper }}>
                <Step label="Sitemap">
                    <SitemapEditor modal={'page'} />
                </Step>
                <Step label="Features">
                    <Features />
                </Step>
                <Step label="Complete">
                    <Complete />
                </Step>
            </Stepper>
        </div>
    );
};

export default withStyles(styles)(Wordpress);
