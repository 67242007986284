import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Input from '../../../components/Input';

import { setProposalByField } from '../../../redux/actions/proposal';

import styles from './styles';

const Complete = props => {
    const { classes, proposal, setProposalByField } = props;

    return (
        <div className={classes.root}>
            <h2>All Finished!</h2>
            <p>Now we just need some information to complete the proposal!</p>
            <form>
                <Grid container spacing={16} direction="column">
                    <Grid item>
                        <Input
                            label="First Name"
                            onChange={e => setProposalByField('First Name', e.target.value)}
                            value={proposal['First Name'].value}
                            errortext={proposal['First Name'].valid ? '' : 'Required'}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            label="Last Name"
                            onChange={e => setProposalByField('Last Name', e.target.value)}
                            value={proposal['Last Name'].value}
                            errortext={proposal['Last Name'].valid ? '' : 'Required'}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            label="Email"
                            onChange={e => setProposalByField('Email', e.target.value)}
                            value={proposal['Email'].value}
                            errortext={proposal['Email'].valid ? '' : 'Please enter a valid email'}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            type="tel"
                            label="Phone Number"
                            onChange={e => setProposalByField('Phone Number', e.target.value)}
                            value={proposal['Phone Number'].value}
                            errortext={proposal['Phone Number'].valid ? '' : 'Please enter a valid phone number'}
                        />
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

const mapStateToProps = ({ proposal }) => ({ proposal });

export default withStyles(styles)(connect(mapStateToProps, { setProposalByField })(Complete));