import { useState, useEffect } from 'react';
import { Auth }                from 'aws-amplify';

import { store }   from '../redux/config';
import { setUser } from '../redux/actions/user';

const useAuthentication = () => {

  const { user } = store.getState();

  const [authFinished, setAuthFinished] = useState(false);
  const [userObject, setUserObject]     = useState(null);

  useEffect(() => {
    if (authFinished === false) {
      // if we found empty user in the redux state
      if (JSON.stringify(user) === '{}') {
        authenticate();
      } else {
        // otherwise skip auth and just return the user
        setUserObject(user);
      }
    }
  }, [authFinished]);

  function authenticate() {
    Auth.currentUserInfo()
    .then(user => {
      setAuthFinished(true);

      if (user) {
        store.dispatch(setUser(user));
        setUserObject(user);
      } else {
        store.dispatch(setUser());
        setUserObject(false);
      }
    })
    .catch(err => {
      console.error(err);
      Auth.signOut();
    });
  }

  return userObject;
}

export default useAuthentication;
