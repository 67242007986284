const styles = theme => ({
    root: {
        flex: 1,
        padding: theme.spacing.unit * 2
    },
    totalCost: {
        borderTop: '1px solid rgba(0, 0, 0, 0.42)'
    }
});

export default styles;